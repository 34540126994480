//:root does not seem to work in Angular components.
//An alternative would be to put them into the global styles.css file instead of a component css file.

@mixin createFontFace($fontfamily, $fontweight, $fontstyle, $url) {
  @font-face {
    font-family: $fontfamily;
    font-weight: $fontweight;
    font-style: $fontstyle;
    src: url($url);
  }
}

@include createFontFace('Roboto', 100, normal, '/fonts/Roboto-Thin.ttf');
@include createFontFace('Roboto', 100, italic, '/fonts/Roboto-ThinItalic.ttf');
@include createFontFace('Roboto', 300, normal, '/fonts/Roboto-Light.ttf');
@include createFontFace('Roboto', 300, italic, '/fonts/Roboto-LightItalic.ttf');
@include createFontFace('Roboto', 400, normal, '/fonts/Roboto-Regular.ttf');
@include createFontFace('Roboto', 400, italic, '/fonts/Roboto-Italic.ttf');
@include createFontFace('Roboto', 500, normal, '/fonts/Roboto-Medium.ttf');
@include createFontFace('Roboto', 500, italic, '/fonts/Roboto-MediumItalic.ttf');
@include createFontFace('Roboto', 700, normal, '/fonts/Roboto-Bold.ttf');
@include createFontFace('Roboto', 700, italic, '/fonts/Roboto-BoldItalic.ttf');
@include createFontFace('Roboto', 900, normal, '/fonts/Roboto-Black.ttf');
@include createFontFace('Roboto', 900, italic, '/fonts/Roboto-BlackItalic.ttf');

//  Responsive design

$desktopBreak: 992px;

*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  --primary-color: #1078d2;
  --primary-color-rbg: 16, 120, 210;
  --secondary-color: #1a315a;
  --backdrop-color: #fff;
  --brand-indigo: #1a315a;
  --detail-color: #4b5563;
  --hint-color: #6b7280;
  --light-color: #fff;
  --dark-color: #111827;
  --frame-color: #d1d5db;
  --support-color: #f5f6f8;
  --danger-color: #d01616;
  --red-50: #fff5f4;
  --success-color: Green;
  --brand-blue: #1078d2;
  --brand-silver: #f5f6f8;
  --disabled-text-color: #909599;
  --disabled-border-color: #b7bcc5;
  // --field-border-radius: 4px;
  --z-index-top: 5;
  --z-index-secondtop: 4;
  --z-index-middle: 3;
  --z-index-secondbottom: 2;
  --z-index-bottom: 1;
}

html {
  font-size: 16px;
}

body {
  font-family: 'Roboto', Tahoma, Arial, sans-serif;
  margin: 0px;
}

p {
  color: var(--greys-600);
  font-size: 0.875rem;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  line-height:1.375rem;
}

main {
  grid-area: main;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

footer {
  grid-area: footer;
}

@import 'styles/typography';

.fact-find {
  .label,
  label {
    font-size: 0.813rem;
    font-weight: 400;
    line-height: 1.125rem;
    height: 1.125rem;
  }

  input[type='text'],
  input[type='number'],
  select,
  textarea {
    padding: 12px 15px;
    border: 1px solid gray;
    border-radius: var(--field-border-radius);
    font-size: 1rem;
    box-sizing: border-box;
    outline: none;
    width: 100%;

    &:focus,
    :focus-visible,
    &:hover {
      border: 1px solid var(--brand-blue);
      box-shadow: 0px 0px 0.3rem var(--brand-blue);
    }
  }

  div.input-with-prefix,
  div.input-with-suffix {
    position: relative;

    .prefix,
    .suffix {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    .prefix {
      left: 15px;
    }

    .suffix {
      right: 15px;
    }

    input[type='number'] {
      height: 2.875rem;
      line-height: 2.875rem;
      border: 1px solid gray;
      border-radius: 4px;

      &:focus,
      :focus-visible,
      &:hover {
        border: 1px solid var(--brand-blue);
        box-shadow: 0px 0px 0.3rem var(--brand-blue);
      }
    }
  }

  div.input-with-prefix {
    input[type='number'] {
      padding: 0 15px 0 40px;
    }
  }

  div.input-with-suffix {
    input[type='number'] {
      padding: 0 40px 0 15px;
    }
  }

  .currency-percent-field {
    display: flex;

    .currency-percent-input {
      flex: 1;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .currency-percent-select {
      width: 7.5rem;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}

@import 'styles/buttons';
@import 'styles/forms';
@import 'styles/grid';
/* To hide default controller for number input */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// buttons

button {
  cursor: pointer;
}

// footer

.ff-footer {
  color: rgb(107 114 128 / 51%);
}

@media (min-height: 500px) {
  .bg-cover .ff-footer {
    color: #ffffff8a;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 3px;
    font-size: 0.625rem;
  }
}

// HELPERS
// display

.d-block {
  display: block;
}

.d-flex {
  display: flex;
}

.w-100 {
  width: 100%;
}

// Kendo PDF Export
kendo-pdf-document {
  .kendo-pdf-ignore {
    display: none !important;
  }
}
