// – _typograhy.SCSS
//     |– Mobile headers
//     |– Desktop headers
//     |– Forms
//     |– Helpers

// MOBILE HEADERS

h1,
h4,
h5 {
  font-weight: 500;
  font-family: 'Roboto';
}

h2,
h3 {
  font-weight: 400;
  font-family: 'Roboto';
}

h1 {
  font-size: 1.62rem;
  line-height: 2.25rem;
  color: var(--secondary-color);
}

h2 {
  font-size: 1.37rem;
  line-height: 1.75rem;
  color: var(--secondary-color);
}

h3 {
  font-size: 1.25rem;
  line-height: 1.62rem;
  color: var(--secondary-color);
}

h4 {
  font-size: 1rem;
  line-height: 1.25rem;
  color: var(--greys-900);
}

h5 {
  font-size: 0.87rem;
  line-height: 1.25rem;
  color: var(--greys-900);
}

h1, h2, h3 {
  color: var(--primary-color);
}

// DESKTOP HEADERS
@media screen and (min-width: 1280px) {
  h1 {
    line-height: 2.25rem;
    font-size: 2rem;
    font-weight: 500;
  }

  h2 {
    line-height: 1.75rem;
    font-size: 1.5rem;
    font-weight: 400;
  }

  h3 {
    line-height: 1.62rem;
    font-size: 1.25rem;
    font-weight: 400;
  }

  h4 {
    line-height: 1.37rem;
    font-size: 1rem;
    font-weight: 400;
  }

  h5 {
    line-height: 1.25rem;
    font-size: 0.87rem;
    font-weight: 400;
  }
}

// MOBILE GENERAL STYLES
@media screen and (max-width: 1279px) {
  .section-heading {
    margin-top: 64px;
    margin-bottom: 2px;

    .section-title {
      font-size: 0.875rem;
      font-weight: 500;
      color: var(--hint-color);
    }
  }

  .page-heading {
    margin-bottom: 36px;

    h1 { // PAGE NAME
      font-size: 1.625rem;
      line-height: 2.125rem;
      font-weight: 500;
      color: var(--primary-color);
      margin: 0;
    }
  }

  mw-groupmenu {
    .top {
      margin-bottom: 12px;

      h3 {
        font-size: 1.25rem;
        font-weight: 300;
        line-height: 1.625rem;
        color: var(--secondary-color);
        margin: 0;
      }
    }
  }

  h4 {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.25rem;
    color: var(--dark-color);
    margin: 0;
  }
}

// FORMS

label {
  cursor: pointer;
}

label > * {
  display: block;
}

// HELPERS

.text-center {
  text-align: center;
}


.placeholder {
  color: var(--hint-color, #6B7280);
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: italic;
  font-weight: 400;
  line-height:1.375rem; /* 157.143% */
}
