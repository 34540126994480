/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
//----------

input {
  &[type='text'],
  &[type='email'],
  &[type='tel'] {
    &:focus-visible {
      outline: none;
    }
  }

  &:focus {
    box-shadow: 0px 0px 8px 0px rgba(2, 126, 195, 0.5);
    border: 1px solid var(--primary-color);
    outline: none;
  }
}

select {
  cursor: pointer;

  &:focus-visible {
    outline: none;
  }

  &:focus {
    //   box-shadow: 0px 0px 8px 0px rgba(2, 126, 195, 0.5);
    //   border: 1px solid var(--primary-color);
    outline: none;
  }
}

input[type='text'],
input[type='number'],
input[type='password'],
input[type='email'],
select,
textarea {
  padding: 0.75rem 0.9385rem;
  align-items: center;
  font-family: Roboto;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  border-radius: var(--field-border-radius);
  border: 1px solid var(--border-border-primary, #d1d5db);
}

.ff-select {
  position: relative;
  display: block;
  border-radius: var(--field-border-radius);
  border: 1px solid var(--greys-350, #b7bcc5);
  border-radius: var(--field-border-radius);
  background: var(--brand-white, #fffefe);
  z-index: var(--z-index-bottom);
}

.ff-select select {
  border: none;
  outline: none;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: var(--field-border-radius);
  margin: 0;
  display: block;
  padding: 0.75rem 2.5rem 0.75rem 0.75rem;
  color: var(--greys-900, #111827);
  width: 100%;

  &:focus {
    outline: 1px solid var(--primary-color);
    box-shadow: 0px 0px 8px 0px rgba(2, 126, 195, 0.5);
  }
}

.ff-select:after {
  position: absolute;
  right: 0;
  top: 0;
  width: 2.5rem;
  height: 100%;
  content: '';
  background-position: center;
  background-repeat: no-repeat;
  font-size: 1.5rem;
  z-index: -1;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1.5L6 6.5L11 1.5' stroke='%231A315A' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
}

.form-input-text {
  font-family: Roboto;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  display: flex;
  padding: 0.625rem 0.9385rem;
  // width: 100%;
  box-sizing: border-box;
  border-radius: var(--field-border-radius);
  border: 1px solid var(--border-border-primary, #d1d5db);

  &:focus {
    box-shadow: 0px 0px 8px 0px rgba(2, 126, 195, 0.5);
    border: 1px solid var(--primary-color);
    outline: none;
  }
}

.form-input-label {
  display: block;
  color: var(--greys-600);
  cursor: pointer;
  // Mobile field label
  font-size: 0.875rem;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem;
  margin-bottom: 0.1885rem;
}

.fact-find {
  .label,
  label {
    font-size: 0.8135rem;
    font-weight: 400;
    line-height: 1.125rem;
    height: 1.125rem;
  }

  input[type='text'],
  input[type='number'],
  textarea {
    padding: 0.75rem 0.9385rem;
    font-size: 1rem;
    box-sizing: border-box;
    outline: none;
    width: 100%;

    &:focus,
    :focus-visible,
    &:hover {
      border: 1px solid var(--primary-color);
      box-shadow: 0px 0px 0.3rem var(--brand-blue);
    }

    &:read-only {
      color: var(--disabled-text-color);
      border: none;
      padding-left: 0px;

      &:focus,
      :focus-visible,
      &:hover {
        border: none;
        box-shadow: none;
      }
    }
  }

  div.input-with-prefix,
  div.input-with-suffix {
    position: relative;

    .prefix,
    .suffix {
      position: absolute;
      // top: 20.1885rem;
      transform: translateY(-50%);
    }

    .prefix {
      left: 0.9385rem;
    }

    .suffix {
      right: 0.9385rem;
    }

    input[type='number'] {
      height: 2.875rem;
      line-height: 2.875rem;

      &:focus,
      :focus-visible,
      &:hover {
        border: 1px solid var(--primary-color);
        box-shadow: 0px 0px 0.3rem var(--brand-blue);
      }
    }
  }

  div.input-with-prefix {
    input[type='number'] {
      padding: 0 0.9385rem 0 2.5rem;
    }
  }

  div.input-with-suffix {
    input[type='number'] {
      padding: 0 2.5rem 0 0.9385rem;
    }
  }

  mw-list {
    input[type='text'],
    input[type='number'],
    textarea {
      &:read-only {
        color: var(--disabled-text-color);
        border: 1px solid var(--disabled-border-color);
        padding-left: 0.9385rem;

        &:focus,
        :focus-visible,
        &:hover {
          border: 1px solid var(--disabled-border-color);
        }
      }
    }
  }
}
// Validation

.can-validate.ng-invalid.ng-touched,
.create-ff-form__input.ng-invalid.ng-touched {
  border-radius: var(--field-border-radius);
  border: 1px solid var(--danger-color) !important;
  background: var(--red-50);
}
// .can-validate.ng-invalid.ng-touched + .create-ff-error-message,
.can-validate.ng-invalid.ng-touched + .create-ff-error-message,
.create-ff-form__input.ng-invalid.ng-touched + .create-ff-error-message {
  display: flex;
}

.input-error-message,
.create-ff-error-message {
  display: none;
  color: var(--danger-color);
  font-size: 0.8135rem;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  gap: 0.625rem;
  margin: 0.1885rem auto;
  align-items: center;

  .warning-message {
    display: inline-block;
    width: 0.8135rem;
    height: 0.75rem;
    background-repeat: no-repeat;
    /* background-color: #FFFFFF; */
    background-image: url('data:image/svg+xml,<svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_1465_1690)"><path d="M12.2533 9.054L7.81855 0.96C7.46607 0.318 6.86365 0 6.26124 0C5.65882 0 5.05641 0.318 4.70393 0.96L0.262726 9.054C-0.179472 9.864 -0.064116 10.944 0.621611 11.532C0.96768 11.826 1.40347 12 1.8713 12H10.6384C11.1126 12 11.5484 11.826 11.8881 11.532C12.5738 10.95 12.6955 9.87 12.2469 9.054H12.2533ZM11.1959 10.812C11.0806 10.908 10.8883 11.028 10.6384 11.028H1.8713C1.62136 11.028 1.4291 10.908 1.31375 10.812C0.993314 10.542 0.935636 9.936 1.17917 9.492L5.62037 1.404C5.77418 1.128 6.00489 0.972 6.26124 0.972C6.51759 0.972 6.7483 1.128 6.90211 1.404L11.3433 9.498C11.5868 9.942 11.5228 10.548 11.2087 10.818L11.1959 10.812Z" fill="%23D01616"/><path d="M6.94073 8.29195L7.1394 3.92395H5.47314L5.67181 8.29195H6.94073Z" fill="%23D01616"/><path d="M6.29345 8.91602C6.04352 8.91602 5.83844 8.98802 5.67181 9.12602C5.50519 9.26402 5.42188 9.44402 5.42188 9.66002C5.42188 9.87602 5.50519 10.056 5.67181 10.194C5.83844 10.332 6.04352 10.404 6.29345 10.404C6.54339 10.404 6.74847 10.332 6.91509 10.194C7.08172 10.056 7.16503 9.87602 7.16503 9.66002C7.16503 9.44402 7.08172 9.26402 6.91509 9.12602C6.74847 8.98802 6.54339 8.91602 6.29345 8.91602Z" fill="%23D01616"/></g><defs><clipPath id="clip0_1465_1690"><rect width="12.5161" height="12" fill="white"/></clipPath></defs></svg>');
    /* background-attachment: fixed | scroll; */
    /* background-size: auto | cover | contain | 500px 250px; */
    /* background-position: center | right 30px bottom 0.9385rem; */
    /* background-repeat: no-repeat | repeat | repeat-x; */
    /* background-origin: border-box | padding-box | content-box; */
    /* background-clip: border-box | padding-box | content-box; */
    /* background-blend-mode: multiply | screen | overlay; */
  }
}

.input-error-message {
  display: flex;
}
// mwValidation

.mw-tooltip > .invalid-input.ng-invalid.ng-touched {
  border-radius: var(--field-border-radius);
  border: 1px solid var(--danger-color) !important;
  background: var(--red-50);
}
