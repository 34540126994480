.ff-button {
    border: 0;
    padding: 12px 15px;
    border-radius: var(--field-border-radius);
    background: var(--support-color);
    display: inline-block;
    border-radius: var(--field-border-radius);
    color: var(--brand-indigo);
    font-weight: 500;
    // width: 100%;

    font-family: Roboto;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height:1.375rem;

    &.ff-button--primary {
        color: white;
        color: var(--support-color);
        border-radius: var(--field-border-radius);
        background: var(--primary-color);

        &:active {
            box-shadow: 0px 0px 10px 0px rgba(26, 49, 90, 0.60);
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), var(--primary-color);
        }
    }

    &.ff-button:hover {
        filter: brightness(80%);
    }

    &:not(.ff-button--primary):active {
        filter: brightness(80%);
        box-shadow: 0px 0px 10px 0px rgba(26, 49, 90, 0.60);
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #F5F6F8;
    }


    &.ff-button:visited {
        filter: brightness(90%);
    }

    &.ff-button:disabled {
        background: var(--greys-300, #D1D5DB);
        color:white;
    }

}
